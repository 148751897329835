// https://raw.githubusercontent.com/kirchner/elm-selectize/2.0.7/demo/static/selectize.scss

// h3 {
//     font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
//     font-weight: normal;
// }

// .container {
//     display: flex;
//     padding: 0.5rem;
// }

// .caption {
//     padding: 6px 8px;
//     font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
//     font-weight: normal;
// }

.selectize {
    &__container {
        position: relative;
        height: 36px;
    }

    &__button {
        border: 1px solid #d0d0d0;
        padding: 6px 8px;
        padding-right: 62px;
        width: 100%;
        height: 35px;
        z-index: 1;
        cursor: pointer;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;

        color: #303030;
        background-color: #ffffff;

        text-align: left;

        font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
        font-size: 15px;
        line-height: 21px;
        -webkit-font-smoothing: inherit;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);

        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;

        &:focus {
            outline: none !important;
            border-color: #66afe9;
        }

        &--light {
            color: #808080;
            cursor: auto;
        }
    }

    &__textfield {
        border: 1px solid #d0d0d0;
        padding: 6px 8px;
        padding-right: 62px;
        width: 100%;
        height: 35px;
        z-index: 1;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;

        color: #303030;

        font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
        font-size: 15px;
        line-height: 21px;
        -webkit-font-smoothing: inherit;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);

        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;

        &:focus {
            outline: none !important;
            border-color: #66afe9;
        }

        &--no-selection {
            &::-webkit-input-placeholder {
                color: #808080;
            }

            &::-moz-placeholder {
                color: #808080;
            }

            &:-ms-input-placeholder {
                color: #808080;
            }

            &:-moz-placeholder {
                color: #808080;
            }
        }

        &--selection {
            &::-webkit-input-placeholder {
                color: #303030;
            }

            &::-moz-placeholder {
                color: #303030;
            }

            &:-ms-input-placeholder {
                color: #303030;
            }

            &:-moz-placeholder {
                color: #303030;
            }
        }

        &--menu-open {
            cursor: auto;
        }
    }

    &__menu-toggle {
        z-index: 1000;
        color: #808080;
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 35px;
        width: 1.8rem;
        cursor: pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
            color: #555555;
        }
    }

    &__menu {
        z-index: 2000;
        width: 100%;
        margin-top: 5px;
        background: white;
        color: black;
        max-height: 20rem;
        overflow: scroll;
        padding: 0;
        margin: 2px 0 0 0;
        color: #000;
        background: #fff;

        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }


    &__list {
        list-style: none;
        padding: 0;
        margin: auto;
        overflow-y: auto;
    }


    &__item {
        display: block;
        padding: 9px 8px;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        color: #555555;
        font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
        font-weight: normal;

        &--mouse-selected {
            background-color: #fafafa;
            color: #495c68;
        }

        &--key-selected {
            background-color: #f5fafd;
            color: #495c68;
        }
    }

    &__divider {
        display: block;
        padding-top: 8px;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-right: 8px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
        line-height: 1;
        color: #555555;
        font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
        font-weight: normal;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    &__icon {
        color: #808080;
    }
}